import staffApi from "@/api/staff";

import i18n from "@/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  member: null,
  staff: {},
  errors: [],
  isResending: false,
  isResetting: false,
  isUpdatingAvatar: false,
  isActivating: false,
  isDeleting: false,
};

const mutations = {
  resetStaffErrors(state) {
    state.errors = [];
  },
  getStaffStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getStaffSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.staff = payload;
  },
  getStaffFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createMemberStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  createMemberSuccess(state) {
    state.isSubmitting = false;
  },
  createMemberFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  getMemberStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getMemberSuccess(state, payload) {
    state.isLoading = false;
    state.member = payload;
  },
  getMemberFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateMemberStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  updateMemberSuccess(state, payload) {
    state.isSubmitting = false;
    state.member = payload;
  },
  updateMemberFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  resendEmailStart(state) {
    state.isResending = true;
    state.errors = [];
  },
  resendEmailSuccess(state, payload) {
    state.isResending = false;
    state.errors = [];
    state.member = payload;
  },
  resendEmailFailed(state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  updateAvatarStart(state) {
    state.isUpdatingAvatar = true;
    state.errors = [];
  },
  updateAvatarSuccess(state, payload) {
    state.isUpdatingAvatar = false;
    state.member = payload;
  },
  updateAvatarFailed(state, payload) {
    state.isUpdatingAvatar = false;
    state.errors = payload;
  },

  deleteAvatarStart(state) {
    state.isDeleting = true;
    state.errors = [];
  },
  deleteAvatarSuccess(state) {
    state.isDeleting = false;
  },
  deleteAvatarFailed(state, payload) {
    state.isDeleting = false;
    state.errors = payload;
  },

  resetPasswordStart(state) {
    state.isResetting = true;
    state.errors = [];
  },
  resetPasswordSuccess(state) {
    state.isResetting = false;
    state.errors = [];
  },
  resetPasswordFailed(state, payload) {
    state.isResetting = false;
    state.errors = payload;
  },

  changeStatusStart(state) {
    state.isActivating = true;
    state.errors = [];
  },
  changeStatusSuccess(state, payload) {
    state.member = payload;
    state.isActivating = false;
    state.errors = [];
  },
  changeStatusFailed(state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },

  resetStaffAvatar(state) {
    state.member.picture = null;
  },
};

const actions = {
  async getStaff(context, payload) {
    try {
      context.commit("getStaffStart");

      const res = await staffApi.getStaff(payload);
      context.commit("getStaffSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getStaffFailed", e.response.data.error);
      return false;
    }
  },

  async createMember(context, payload) {
    try {
      console.log("createMemberStart");
      context.commit("createMemberStart");

      await staffApi.createMember(payload);
      context.commit("createMemberSuccess");
      return true;
    } catch (e) {
      console.log(e);
      context.commit("createMemberFailed", e.response.data.error);
      return false;
    }
  },

  async getMember(context, payload) {
    try {
      context.commit("getMemberStart");

      const res = await staffApi.getMember(payload);
      context.commit("getMemberSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getMemberFailed", e.response.data.error);
      return false;
    }
  },

  async updateMember(context, { id, data }) {
    try {
      context.commit("updateMemberStart");

      const res = await staffApi.updateMember(id, data);
      context.commit("updateMemberSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateMemberFailed", e.response.data.error);
      return false;
    }
  },

  async staffResendEmail(context, payload) {
    try {
      context.commit("resendEmailStart");

      const res = await staffApi.resendEmail(payload);
      context.commit("resendEmailSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("resendEmailFailed", e.response.data.error);
      return false;
    }
  },

  async staffUpdateAvatar(context, { id, data }) {
    try {
      context.commit("updateAvatarStart");
      const res = await staffApi.updateAvatar(id, data);
      context.commit("updateAvatarSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateAvatarFailed", e.response.data.error);
      return false;
    }
  },

  async staffDeleteAvatar(context, payload) {
    try {
      context.commit("deleteAvatarStart");
      await staffApi.deleteAvatar(payload);
      context.commit("deleteAvatarSuccess");
      return true;
    } catch (e) {
      context.commit("deleteAvatarFailed", e.response.data.error);
      return false;
    }
  },

  async resetStaffPassword(context, payload) {
    try {
      context.commit("resetPasswordStart");

      await staffApi.resetPassword(payload);
      context.commit("resetPasswordSuccess");
      return true;
    } catch (e) {
      context.commit("resetPasswordFailed", e.response.data.error);
      return false;
    }
  },

  async suspendMember(context, payload) {
    try {
      context.commit("changeStatusStart");

      const res = await staffApi.suspendMember(payload);
      context.commit("changeStatusSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("changeStatusFailed", e.response.data.error);
      return false;
    }
  },

  async restoreMember(context, payload) {
    try {
      context.commit("changeStatusStart");

      const res = await staffApi.restoreMember(payload);
      context.commit("changeStatusSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("changeStatusFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  staffList(state) {
    return state.staff?.result || [];
  },
  memberFullName(state) {
    const { first_name, last_name } = state.member;
    return `${first_name} ${last_name}`;
  },
  staffIsEmailConfirmed(state) {
    const confirmation = state.member.is_email_confirmed;
    return confirmation === undefined ? true : confirmation;
  },
  staffFirstNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "first_name__required") &&
      result.push(i18n.t("inputError.firstNameRequired"));
    errors.find((i) => i == "first_name__invalid") &&
      result.push(i18n.t("inputError.firstNameInvalid"));
    return result;
  },
  staffLastNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "last_name__required") &&
      result.push(i18n.t("inputError.lastNameRequired"));
    errors.find((i) => i == "last_name__invalid") &&
      result.push(i18n.t("inputError.lastNameInvalid"));
    return result;
  },
  staffEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "email__required") &&
      result.push(i18n.t("inputError.emailRequired"));
    errors.find((i) => i == "email__invalid") &&
      result.push(i18n.t("inputError.emailInvalid"));
    errors.find((i) => i === "email__exists") &&
      result.push(i18n.t("inputError.emailExists"));
    errors.find((i) => i === "email__update_too_often") &&
      result.push(i18n.t("inputError.emailUpdTooOften"));
    return result;
  },
  staffRoleErrors({ errors }) {
    const result = [];
    errors.find((i) => i == "role__required") &&
      result.push(i18n.t("inputError.roleRequired"));
    errors.find((i) => i == "role__invalid") &&
      result.push(i18n.t("inputError.roleInvalid"));
    return result;
  },
  staffIsSuspended(state) {
    return state.member ? state.member.is_suspended : false;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
