import "@/plugins/axios";

const urlPath = "/platform/api/v1/statistic/";

const getCounters = () => {
  return axios.get(`${urlPath}counters`);
};

const getCustomersChart = (payload) => {
  return axios.get(`${urlPath}customers-chart?period=${payload}`);
};

const getEndowmentsChart = (payload) => {
  return axios.get(`${urlPath}endowments-chart?period=${payload}`);
};

export default {
  getCounters,
  getCustomersChart,
  getEndowmentsChart,
};
