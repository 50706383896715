import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support', 'operation_officer', 'waqf_agent'],
      },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/profile",
      name: "Profile",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support', 'operation_officer', 'waqf_agent'],
      },
      component: () => import("@/views/Profile.vue"),
    },
    {
      path: "/staff",
      name: "Staff",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support'],
      },
      component: () => import("@/views/StaffList.vue"),
    },
    {
      path: "/staff/:id",
      name: "Edit staff account",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support'],
      },
      component: () => import("@/views/StaffMember.vue"),
    },
    {
      path: "/templates",
      name: "Templates",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support', 'operation_officer'],
      },
      component: () => import("@/views/Templates.vue"),
    },
    {
      path: "/templates/:id",
      name: "Template",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support', 'operation_officer'],
      },
      component: () => import("@/views/Template.vue"),
    },
    {
      path: "/endowments",
      name: "Endowments",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support', 'operation_officer', 'waqf_agent'],
      },
      component: () => import("@/views/Endowments.vue"),
    },
    {
      path: "/endowments/:id",
      name: "Endowment",
      meta: {
        layout: "Main",
        auth: true,
        access: ['admin', 'support', 'operation_officer', 'waqf_agent'],
      },
      component: () => import("@/views/Endowment.vue"),
    },
    {
      path: "/sign-in",
      name: "Sign in",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/sign-in/otp",
      name: "Sign in otp",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/LoginConfirm.vue"),
    },
    {
      path: "/sign-in/otp-recovery",
      name: "Sign in otp recovery",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/OtpRecovery.vue"),
    },
    {
      path: "/forgot-password",
      name: "Password reset",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PasswordReset.vue"),
    },
    {
      path: "/password-reset-sent",
      name: "Password reset sent",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PasswordResetSent.vue"),
    },
    {
      path: "/password-reset",
      name: "Reset password",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PasswordRecovery.vue"),
    },
    {
      path: "/email-confirmation",
      name: "Email confirmation",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/ConfirmEmail.vue"),
    },
    {
      path: "*",
      redirect: { name: "Home" },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem("accessToken");
  const requireAuth = to.matched.some((record) => record.meta.auth);
  const role = sessionStorage.getItem('role');
  const access = requireAuth && role ? !to.matched.some((record) => record.meta.access.find((i) => i == role)) : null;

  if (requireAuth && !currentUser) {
    next({ name: 'Sign in', query: { from: to.fullPath } });
  } else if (access) {
    next('/');
  } else {
    next();
  }
});

export default router;
