import "@/plugins/axios";

const urlPath = "/platform/api/v1/endowment-templates/";

const getTemplates = () => {
  return axios.get(urlPath);
};

const getEndowmentTypes = () => {
  return axios.get("/platform/api/v2/endowment-types/");
};

const createTemplate = (data) => {
  return axios.post(urlPath, data);
};

const getTemplate = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const toggleTemplate = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/action`, data);
};

const updateCommon = ({ id, data }) => {
  return axios.put(`${urlPath}${id}`, data);
};

const updateStep = ({ templateId, stepId, data }) => {
  return axios.put(`${urlPath}${templateId}/step/${stepId}`, data);
};

const addSection = ({ templateId, stepId, data }) => {
  return axios.post(`${urlPath}${templateId}/step/${stepId}/sections/`, data);
};

const addStep = ({ templateId, data }) => {
  return axios.post(`${urlPath}${templateId}/step/`, data);
};

const deleteStep = ({ templateId, stepId }) => {
  return axios.delete(`${urlPath}${templateId}/step/${stepId}`);
};

const deleteSection = ({ templateId, stepId, sectionId }) => {
  return axios.delete(
    `${urlPath}${templateId}/step/${stepId}/sections/${sectionId}`
  );
};

const updateSection = ({ templateId, stepId, sectionId, data }) => {
  return axios.put(
    `${urlPath}${templateId}/step/${stepId}/sections/${sectionId}`,
    data
  );
};

const updateOrder = ({ templateId, stepId, sectionId, data }) => {
  return axios.post(
    `${urlPath}${templateId}/step/${stepId}/sections/${sectionId}/order`,
    data
  );
};

export default {
  getTemplates,
  getEndowmentTypes,
  createTemplate,
  getTemplate,
  toggleTemplate,
  updateCommon,
  updateStep,
  addSection,
  deleteSection,
  updateSection,
  addStep,
  deleteStep,
  updateOrder,
};
