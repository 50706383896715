import Vue from "vue";
import Vuex from "vuex";
import common from "./modules/common";
import auth from "./modules/auth";
import profile from "./modules/profile";
import staff from "./modules/staff";
import template from "./modules/template";
import endowment from "./modules/endowment";
import statistic from "./modules/statistic";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    profile,
    staff,
    template,
    endowment,
    statistic,
  },
});
