<template>
  <div>
    <v-navigation-drawer
      fixed
      overlay-opacity
      temporary
      v-model="drawer"
      width="220"
      class="mobile-menu"
    >
      <!-- <div class="logo">
        <v-img max-height="48" max-width="34" contain src="@/assets/icon/logo.svg" alt="logo"></v-img>
      </div> -->
      <MenuList />
    </v-navigation-drawer>
    <v-app-bar elevation="0" height="70" color="accent" class="header">
      <div class="width100 d-flex flex-wrap align-center px-2">
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="d-lg-none d-block me-3"
        >
          <v-icon v-if="!drawer">mdi-menu</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-app-bar-nav-icon>
        <router-link class="d-flex align-center" to="/">
          <v-img
            max-height="48"
            max-width="34"
            contain
            src="@/assets/icon/logo.svg"
            alt="logo"
          ></v-img>
          <span class="f18 primary--text ms-4">Back-office</span>
        </router-link>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-card
              height="40"
              flat
              outlined
              class="rounded d-flex align-center px-4 py-1 me-10"
              v-bind="attrs"
              v-on="on"
            >
              <img src="@/assets/icon/globe.svg" alt="globe" />
              <div class="gray--text mx-2">{{ $t("language") }}</div>
              <div class="primary--text me-1">{{ $t("lang") }}</div>
              <v-icon color="primary">{{ value ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-card>
          </template>
          <v-list>
            <v-list-item
              v-for="item in lang"
              :key="item.key"
              @click="changeLang(item.key)"
            >
              <img :src="flagImg(item.key == 'ar' ? 'sa' : 'us')" alt="flag" />
              <span class="px-5">{{ item.name }}</span>
              <v-icon>{{
                $vuetify.rtl ? "mdi-chevron-left" : "mdi-chevron-right"
              }}</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-img
          class="link"
          height="24"
          max-width="24"
          contain
          src="@/assets/icon/bell.svg"
        ></v-img>
        <div class="text-center ms-5">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-list color="accent" class="pa-0">
                  <v-list-item>
                    <v-list-item-avatar size="40" color="gray lighten-3 me-3">
                      <v-img
                        v-if="profile.picture != null"
                        :src="profile.picture"
                      ></v-img>
                      <div v-else>
                        <v-icon color="gray">mdi-account</v-icon>
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-title class="black--text font-weight-bold">
                      {{ profile.first_name }} {{ profile.last_name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </template>
            <v-list>
              <v-list-item link to="/profile">
                <v-list-item-icon>
                  <v-icon color="gray">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("btn.profile") }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <v-icon color="gray">mdi-download mdi-rotate-270</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("btn.signOut") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import MenuList from "./MenuList.vue";

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      drawer: false,
      lang: [
        { name: "English", key: "en" },
        { name: "Arabic", key: "ar" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getProfile");
    this.checkLocalization();
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    checkLocalization() {
      this.axios.defaults.headers.common["Accept-Language"] =
        localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar";
      this.$i18n.locale = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "ar";
      this.$vuetify.rtl = localStorage.getItem("lang")
        ? localStorage.getItem("lang") == "ar"
        : true;
    },
    changeLang(item) {
      this.axios.defaults.headers.common["Accept-Language"] = item;
      this.$i18n.locale = item;
      localStorage.setItem("lang", item);
      this.$store.dispatch("setLang", item);
      this.$vuetify.rtl = item ? item == "ar" : true;
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style lang="scss">
$heigth: 71px;
.header .v-toolbar__content {
  margin-left: auto;
}
.mobile-menu {
  top: $heigth !important;
  height: calc(100% - $heigth) !important;
}
@media screen and (max-width: 1263px) {
  .header .v-toolbar__content {
    width: initial;
    margin-left: initial;
  }
}
</style>
