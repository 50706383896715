import "@/plugins/axios";

const urlPath = "/platform/api/v1/endowments/";

const getEndowments = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getEndowment = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const downloadEndowment = (id) => {
  return axios.get(`${urlPath}${id}/download?t=${sessionStorage.getItem("accessToken")}`);
};

const uploadVerified = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/upload-verified`, data);
};

export default {
  getEndowments,
  getEndowment,
  downloadEndowment,
  uploadVerified,
};
