import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#22a7ab",
        accent: "#f7f9fc",
        secondary: "#065559",
        success: {
          base: "#22ab60",
          lighten5: "#dcffec",
        },
        warning: {
          base: "#ffc700",
          lighten5: "#fff6d7",
        },
        error: "#f44336",
        gray: "#909ab0",
        "gray-1": "#E4E3E3",
        black: "#142140",
        "green-1": "#22AB60",
      },
    },
  },
});
