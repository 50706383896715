import "@/plugins/axios";

const api = "/platform/api/v1/profile";
const api2fa = "/platform/api/v1/profile/2fa/otp/";

export default {
  state: {
    profile: {},
    codes: [],
    qr: {},
  },
  actions: {
    getProfile(context) {
      return axios.get(`${api}`).then((res) => {
        sessionStorage.setItem('role', res.data.role);
        context.commit("GET_PROFILE", res.data);
      });
    },
    setProfile: (context) => {
      context.commit("GET_PROFILE", {});
    },
    updateProfile(context, payload) {
      return axios.put(`${api}`, payload).then((res) => {
        sessionStorage.setItem('role', res.data.role);
        context.commit("GET_PROFILE", res.data);
      });
    },
    newPasswordProfile(context, payload) {
      return axios.post(`${api}/password`, payload);
    },
    setPhoto(context, payload) {
      return axios({
        method: "post",
        url: `${api}/picture`,
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        context.commit("GET_PROFILE", res.data);
      });
    },
    deletePhoto(context) {
      return axios.delete(`${api}/picture`);
    },
    resendEmailProfile(context, payload) {
      return axios
        .post(`${api}/resend-email-confirmation`, payload)
        .then((res) => {
          context.commit("GET_PROFILE", res.data);
        });
    },
    getCodes(context) {
      return axios.get(api2fa + "recovery-codes").then((res) => {
        context.commit("GET_CODES", res.data);
      });
    },
    downloadCodes(context) {
      return axios.get(api2fa + "recovery-codes/download").then((res) => {
        const type = res.headers["content-type"];
        const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "codes.txt";
        link.click();
      });
    },
    generate(context) {
      return axios.post(api2fa + "generate-recovery-codes").then((res) => {
        context.commit("GET_CODES", res.data);
      });
    },
    step2(context) {
      return axios.get(api2fa + "prepare").then((res) => {
        context.commit("GET_QR", res.data);
      });
    },
    enable(context, payload) {
      return axios
        .post(api2fa + "enable", {
          code: payload,
        })
        .then(() => {
          context.dispatch("getProfile");
        });
    },
    disable(context) {
      return axios.post(api2fa + "disable").then((res) => {
        context.dispatch("getProfile");
      });
    },
  },
  mutations: {
    GET_PROFILE: (state, payload) => {
      state.profile = payload;
      return state.profile;
    },
    GET_CODES: (state, payload) => {
      state.codes = payload;
      return state.codes;
    },
    GET_QR: (state, payload) => {
      state.qr = payload;
      return state.qr;
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    codes(state) {
      return state.codes;
    },
    qr(state) {
      return state.qr;
    },
  },
};
