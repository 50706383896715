<template>
  <v-card tile min-width="248" max-width="249" class="card">
    <!-- <div class="logo">
      <v-img max-height="38" max-width="108" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
    </div> -->
    <MenuList />
  </v-card>
</template>

<script>
import MenuList from "./MenuList.vue";

export default {
  components: {
    MenuList,
  },
};
</script>

<style scoped>
.card {
  /* margin-top: -68px; */
  min-height: calc(100vh - 71px);
}
</style>
