<template>
  <v-app :key="key">
    <Header />
    <v-divider></v-divider>
    <div class="main-layout relative d-flex">
      <notifications></notifications>
      <Menu class="d-none d-lg-block" />
      <router-view class="content-view" />
    </div>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Menu from "@/components/Menu";

export default {
  components: {
    Header,
    Menu,
  },
  data() {
    return {
      key: 1,
    };
  },
  watch: {
    lang() {
      this.key = ++this.key;
    },
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style>
.main-layout .content-view {
  max-width: 1256px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 32px 20px;
}
@media screen and (max-width: 1200px) {
  .main-layout .content-view {
    padding: 20px 32px 20px;
  }
}
</style>
