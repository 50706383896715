<template>
  <v-list flat color="white" class="py-6">
    <v-list-item-group>
      <v-list-item
        v-for="item in items"
        :key="item.link"
        v-show="item.access.some((item) => item == profile.role)"
        link
        :to="`/${item.link}`"
        class="mx-5"
        active-class="active"
      >
        <div class="d-flex align-center">
          <v-img
            height="24"
            max-width="24"
            :src="
              getImage(
                $route.path == `/${item.link}` ? `${item.icon}-fill` : item.icon
              )
            "
            class="icon me-4"
          ></v-img>
          <div class="py-2 f15 black--text">{{ item.title }}</div>
        </div>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: this.$t("headers.staff"), link: "staff", icon: "staff", access: ['admin', 'support'] },
        {
          title: this.$tc("headers.template", 2),
          link: "templates",
          icon: "edit",
          access: ['admin', 'support', 'operation_officer'],
        },
        {
          title: this.$tc("headers.endowment", 2),
          link: "endowments",
          icon: "endowments",
          access: ['admin', 'support', 'operation_officer', 'waqf_agent'],
        },
      ],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background: #f7f9fc;
  border-radius: 16px;
  .v-list-item__icon {
    filter: invert(57%) sepia(51%) saturate(5266%) hue-rotate(148deg)
      brightness(100%) contrast(73%);
  }
  .v-list-item__title {
    font-weight: 700;
    color: var(--v-primary-base) !important;
  }
}
</style>
