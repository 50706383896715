import api from "@/api/statistic";

const state = {
  isStatisticCounterLoading: false,
  isStatisticCuctomerLoading: false,
  isStatisticEndowmentLoading: false,
  statisticCounters: {},
  statisticCustomerChart: {},
  statisticEndowmentsChart: {},
  errors: [],
  endowment: null,
};

const mutations = {
  resetStatisticErrors(state) {
    state.errors = [];
  },
  getCountersStart(state) {
    state.isCounterLoading = true;
    state.errors = [];
  },
  getCountersSuccess(state, payload) {
    state.isCounterLoading = false;
    state.errors = [];
    state.statisticCounters = payload;
  },
  getCountersFailed(state, payload) {
    state.isCounterLoading = false;
    state.errors = payload;
  },

  getCustomerStart(state) {
    state.isCuctomerLoading = true;
    state.errors = [];
  },
  getCustomerSuccess(state, payload) {
    state.isCuctomerLoading = false;
    state.errors = [];
    state.statisticCustomerChart = payload;
  },
  getCustomerFailed(state, payload) {
    state.isCuctomerLoading = false;
    state.errors = payload;
  },

  getEndowmentStart(state) {
    state.isEndowmentLoading = true;
    state.errors = [];
  },
  getEndowmentSuccess(state, payload) {
    state.isEndowmentLoading = false;
    state.errors = [];
    state.statisticEndowmentsChart = payload;
  },
  getEndowmentFailed(state, payload) {
    state.isEndowmentLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getStatisticCounters(context) {
    try {
      context.commit("getCountersStart");
      const res = await api.getCounters();
      context.commit("getCountersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCountersFailed", e.response.data.error);
      return false;
    }
  },
  async getStatisticCustomersChart(context, payload) {
    try {
      context.commit("getCustomerStart");
      const res = await api.getCustomersChart(payload);
      context.commit("getCustomerSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCustomerFailed", e.response.data.error);
      return false;
    }
  },
  async getStatisticEndowmensChart(context, payload) {
    try {
      context.commit("getEndowmentStart");
      const res = await api.getEndowmentsChart(payload);
      context.commit("getEndowmentSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getEndowmentFailed", e.response.data.error);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
