<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
#app {
  * {
    font-family: "Frutiger", sans-serif !important;
    letter-spacing: 0 !important;
    // line-height: 1.4 !important;
  }
  .v-radio {
    align-items: start;
  }
  .warning-card {
    background: var(--v-warning-lighten5);
    border: 1px solid var(--v-warning-base);
  }

  .f12 {
    font-size: 12px;
  }
  .f14 {
    font-size: 14px;
  }
  .f15 {
    font-size: 15px;
  }
  .f16 {
    font-size: 16px;
  }
  .f18 {
    font-size: 18px;
  }
  .f22 {
    font-size: 22px;
  }
  .f27 {
    font-size: 27px;
  }
  .f28 {
    font-size: 28px;
  }
  .f30 {
    font-size: 30px;
  }

  .v-data-table {
    border: 1px solid #e4e3e3;
    border-radius: 0px;
    max-width: 2000px !important;
    margin: 0 auto;
    overflow: hidden;
    thead {
      tr {
        th {
          color: #909ab0;
          background-color: #f4f4f4 !important;
          vertical-align: middle;
          font-size: 16px;
          font-weight: 400;

          .v-input {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &.cursor--default {
          cursor: default;
        }
      }
      td {
        font-size: 16px;
        vertical-align: middle;
        color: #142140;

        .v-input {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
}
//.v-btn.v-size--default {
//  font-size: 16px;
//}
.v-data-table {
  border-radius: 16px !important;
}
@font-face {
  font-family: "Frutiger";
  src: url("./assets/fonts/FrutigerLTArabicNormal.ttf") format("truetype");
}

@font-face {
  font-family: "Frutiger Bold";
  src: url("./assets/fonts/FrutigerLTArabicBold.ttf") format("truetype");
}

@font-face {
  font-family: "Frutiger Light";
  src: url("./assets/fonts/FrutigerLTArabicLight.ttf") format("truetype");
}

@font-face {
  font-family: "Frutiger Black";
  src: url("./assets/fonts/FrutigerltarabicBlack.ttf") format("truetype");
}
#app .arabic-black {
  font-family: "Frutiger Black", sans-serif !important;
}

#app .arabic-bold {
  font-family: "Frutiger Bold", sans-serif !important;
}

#app .arabic-light {
  font-family: "Frutiger Light", sans-serif !important;
}

#app .arabic-default {
  font-family: "Frutiger", sans-serif !important;
}
body,
#app {
  background: #f7f9fc;
}
.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.view-without-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a {
  text-decoration: none;
}
.wrap {
  max-width: 1440px;
  width: 100%;
  padding: 0 46px;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
.height100 {
  height: 100%;
}
.link {
  cursor: pointer;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
}
.btn-underline {
  position: relative;
  width: fit-content;
  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    background: var(--v-secondary-base);
  }
  &:hover:after {
    display: none;
  }
}
.v-btn__content {
  text-transform: none;
  opacity: 1 !important;
}
.btn-shadow {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.08);
}
.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
}
// .v-text-field--outlined fieldset {
//   top: -10px !important;
// }
::placeholder {
  color: var(--v-gray-base) !important;
}
.input-center input {
  text-align: center;
  font-size: 18px;
  padding: 0;
}

.radius34 {
  border-radius: 34px !important;
}
.radius24 {
  border-radius: 24px !important;
}
.radius22 {
  border-radius: 22px !important;
}

.radius16 {
  border-radius: 16px !important;
}

.radius12 {
  border-radius: 12px !important;
}

.radius10 {
  border-radius: 10px !important;
}
.radius8 {
  border-radius: 8px !important;
}

.loader--center {
  position: absolute;
  left: calc(50% + 120px);
  top: 50%;
  transform: translate(-50%, -50%);
}

.v-application {
  & p {
    margin-bottom: 0 !important;
  }
}

.v-dialog {
  border-radius: 16px !important;
}

.first-upper {
  &::first-letter {
    text-transform: capitalize;
  }
}

.relative {
  position: relative !important;
}

label {
  display: block;
  margin-bottom: 8px;
}

.v-input .v-input__slot {
  border-radius: 10px !important;
}

fieldset {
  border: none;
}

.fieldset {
  max-width: 244px;
}

.template-section {
  border: 1px solid #e4e3e3 !important;
  background-color: white;
  position: relative;
}

.template-step {
  position: relative;
  padding-top: 56px;

  &__top {
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px 20px;
    background: #065559;
    border-radius: 16px;
    width: 100%;
    height: 50%;
  }
}

.bg-white {
  background-color: white;
}

.bg-gray {
  background-color: #f7f9fc;
}

.border-gray {
  border: 1px solid #e4e3e3 !important;
}

.v-input--checkbox label {
  margin-bottom: 0;
}

.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}

// .v-input__control > .v-input__slot {
//   min-height: 36px !important;
//   height: 52px;
// }

.search-field .v-input__prepend-inner {
  margin-top: 12px !important;
}
.mb2 {
  margin-bottom: 2px;
}

.v-data-footer .v-input__append-inner {
  position: absolute !important;
  left: 20px !important;
}
.v-data-footer__pagination {
  font-size: 16px !important;
}
.v-data-footer__select {
  margin-right: auto !important;
  font-size: 16px;
  margin-left: 16px !important;
  & .v-input__append-inner {
    margin-right: 10px !important;
  }

  & .v-select__selection {
    font-size: 16px !important;
  }
}
.v-application--is-rtl {
  .v-data-footer__select {
    margin-right: 16px !important;
    margin-left: auto !important;
  }
}
</style>
