import "@/plugins/axios";

const api = "/api/v1/";

export default {
  state: {
    langValue: localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "ar",
    phoneCountry: [],
  },
  actions: {
    setLang: (context, payload) => {
      context.commit("GET_LANG", payload);
    },
    getPhoneCountry(context) {
      return axios.get(`${api}allowed-country-codes/`).then((res) => {
        context.commit("GET_PHONECOUNTRY", res.data);
      });
    },
    setPhoneCountry(context) {
      context.commit("GET_PHONECOUNTRY", []);
    },
  },
  mutations: {
    GET_LANG: (state, payload) => {
      state.langValue = payload;
      return state.langValue;
    },
    GET_PHONECOUNTRY: (state, payload) => {
      state.phoneCountry = payload;
      return state.phoneCountry;
    },
  },
  getters: {
    langValue(state) {
      return state.langValue;
    },
    phoneCountry(state) {
      return state.phoneCountry;
    },
  },
};
