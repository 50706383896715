import endowmentApi from "@/api/endowment";
import i18n from "@/i18n";
import { itemsPerPage } from "@/helpers/pagination";

const state = {
  isLoading: false,
  endowments: {},
  errors: [],
  searchValue: "",
  page: 1,
  itemsPerPage: itemsPerPage,
  endowment: null,
  isDownloading: false,
  isUploading: false,
};

const mutations = {
  changeEndowmentsPagination(state, { page, itemsPerPage }) {
    state.page = page;
    state.itemsPerPage = itemsPerPage;
  },
  changeEndowmentsSearchValue(state, payload) {
    state.searchValue = payload;
  },
  resetEndowmentsErrors(state) {
    state.errors = [];
  },
  getEndowmentsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getEndowmentsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.endowments = payload;
  },
  getEndowmentsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getEndowmentStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getEndowmentSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.endowment = payload;
  },
  getEndowmentFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  downloadEndowmentStart(state) {
    state.isDownloading = true;
  },
  downloadEndowmentSuccess(state) {
    state.isDownloading = false;
    state.errors = [];
  },
  downloadEndowmentFailed(state, payload) {
    state.isDownloading = false;
    state.errors = payload;
  },

  uploadVerifiedStart(state) {
    state.isUploading = true;
  },
  uploadVerifiedSuccess(state, payload) {
    state.isUploading = false;
    state.errors = [];
    state.endowment = payload;
  },
  uploadVerifiedFailed(state, payload) {
    state.isUploading = false;
    state.errors = payload;
  },
};

const actions = {
  async getEndowments(context, payload) {
    try {
      context.commit("getEndowmentsStart");
      const res = await endowmentApi.getEndowments(payload);
      context.commit("getEndowmentsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getEndowmentsFailed", e.response.data.error);
      return false;
    }
  },
  async getEndowment(context, payload) {
    try {
      context.commit("getEndowmentStart");
      const res = await endowmentApi.getEndowment(payload);
      context.commit("getEndowmentSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getEndowmentFailed", e.response.data.error);
      return false;
    }
  },
  async downloadEndowment(context, payload) {
    try {
      context.commit("downloadEndowmentStart");
      const res = await endowmentApi.downloadEndowment(payload);
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
        encoding: "UTF-8",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "endowment.pdf";
      link.click();
      context.commit("downloadEndowmentSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("downloadEndowmentFailed", e.response.data.error);
      return false;
    }
  },
  async uploadVerified(context, payload) {
    try {
      context.commit("uploadVerifiedStart");
      const res = await endowmentApi.uploadVerified(payload);
      context.commit("uploadVerifiedSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("uploadVerifiedFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  endowmentsTotalPages(state) {
    return state.endowments?.pages || 0;
  },
  endowmentsTotalItems(state) {
    return state.endowments?.total || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
