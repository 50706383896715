import templateApi from "@/api/template";
import i18n from "@/i18n";

const state = {
  isLoading: false,
  templates: null,
  errors: [],
  endowmentTypes: [],
  isCreating: false,
  template: null,
  isToggling: false,
  isAdding: false,
};

const mutations = {
  resetStaffErrors(state) {
    state.errors = [];
  },
  getTemplatesStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getTemplatesSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.templates = payload;
  },
  getTemplatesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getTemplateStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getTemplateSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.template = payload;
  },
  getTemplateFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getEndowmentTypesSuccess(state, payload) {
    state.endowmentTypes = payload;
  },

  createTemplateStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  createTemplateSuccess(state, payload) {
    state.isCreating = false;
    state.errors = [];
    state.template = payload;
  },
  createTemplateFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  toggleTemplateStart(state) {
    state.isToggling = true;
    state.errors = [];
  },
  toggleTemplateSuccess(state, payload) {
    state.isToggling = false;
    state.errors = [];
    state.template = payload;
  },
  toggleTemplateFailed(state, payload) {
    state.isToggling = false;
    state.errors = payload;
  },

  setErrors(state, payload) {
    state.errors = payload;
  },

  addSectionStart(state) {
    state.isCreating = true;
    state.errors = [];
  },
  addSectionSuccess(state, { section, stepId }) {
    state.isCreating = false;
    state.errors = [];
    const stepInd = state.template.steps.findIndex(
      (step) => step.id === stepId
    );

    state.template.steps[stepInd].sections.push(section);
  },
  addSectionFailed(state, payload) {
    state.isCreating = false;
    state.errors = payload;
  },

  removeSection(state, { stepId, sectionId }) {
    const stepInd = state.template.steps.findIndex(
      (step) => step.id === stepId
    );

    state.template.steps[stepInd].sections = state.template.steps[
      stepInd
    ].sections.filter((section) => section.id !== sectionId);
  },

  changeSectionOrder(state, { stepId, removedIndex, addedIndex }) {
    const stepInd = state.template.steps.findIndex(
      (step) => step.id === stepId
    );

    const sections = [...state.template.steps[stepInd].sections];
    let changedSection = sections[addedIndex];
    state.template.steps[stepInd].sections[addedIndex] = sections[removedIndex];

    if (removedIndex < addedIndex) {
      for (let i = addedIndex - 1; i >= removedIndex; i--) {
        const temp = sections[i];
        state.template.steps[stepInd].sections[i] = changedSection;
        changedSection = temp;
      }
    } else if (removedIndex > addedIndex) {
      for (let i = addedIndex + 1; i <= removedIndex; i++) {
        const temp = sections[i];
        state.template.steps[stepInd].sections[i] = changedSection;
        changedSection = temp;
      }
    }

    state.template = { ...state.template };
  },

  removeStep(state, { stepId }) {
    state.template.steps = state.template.steps.filter(
      (step) => step.id !== stepId
    );
  },

  addStepStart(state) {
    state.isAdding = true;
    state.errors = [];
  },
  addStepSuccess(state, payload) {
    state.isAdding = false;
    state.errors = [];
    state.template.steps.push(payload);
  },
  addStepFailed(state, payload) {
    state.isAdding = false;
    state.errors = payload;
  },
};

const actions = {
  async getTemplates(context) {
    try {
      context.commit("getTemplatesStart");

      const res = await templateApi.getTemplates();
      context.commit("getTemplatesSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getTemplatesFailed", e.response.data.error);
      return false;
    }
  },

  async getEndowmentTypes(context) {
    try {
      const res = await templateApi.getEndowmentTypes();
      context.commit("getEndowmentTypesSuccess", res.data);
      return true;
    } catch (e) {
      return false;
    }
  },

  async createTemplate(context, payload) {
    try {
      context.commit("createTemplateStart");

      const res = await templateApi.createTemplate(payload);
      context.commit("createTemplateSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("createTemplateFailed", e.response.data.error);
      return false;
    }
  },

  async getTemplate(context, payload) {
    try {
      context.commit("getTemplateStart");

      const res = await templateApi.getTemplate(payload);
      context.commit("getTemplateSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getTemplateFailed", e.response.data.error);
      return false;
    }
  },

  async toggleTemplate(context, payload) {
    try {
      context.commit("toggleTemplateStart");

      const res = await templateApi.toggleTemplate(payload);
      context.commit("toggleTemplateSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("toggleTemplateFailed", e.response.data.error);
      return false;
    }
  },

  async updateCommon(context, payload) {
    try {
      await templateApi.updateCommon(payload);
      context.commit("setErrors", []);
      return true;
    } catch (e) {
      context.commit("setErrors", e.response.data.error);
      return false;
    }
  },

  async updateStep(context, payload) {
    try {
      await templateApi.updateStep(payload);
      context.commit("setErrors", []);
      return true;
    } catch (e) {
      context.commit("setErrors", e.response.data.error);
      return false;
    }
  },

  async addSection(context, payload) {
    try {
      context.commit("addSectionStart");

      const res = await templateApi.addSection(payload);
      context.commit("addSectionSuccess", {
        section: res.data,
        stepId: payload.stepId,
      });
      return true;
    } catch (e) {
      context.commit("addSectionFailed", e.response.data.error);
      return false;
    }
  },

  async addStep(context, payload) {
    try {
      context.commit("addStepStart");

      const res = await templateApi.addStep(payload);
      context.commit("addStepSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("addStepFailed", e.response.data.error);
      return false;
    }
  },

  async deleteSection(context, payload) {
    try {
      context.commit("removeSection", payload);
      await templateApi.deleteSection(payload);
      return true;
    } catch (e) {
      context.commit("setErrors", e.response.data.error);
      return false;
    }
  },

  async updateSectionsOrder(context, payload) {
    try {
      await templateApi.updateOrder(payload);
      return true;
    } catch (e) {
      context.commit("setErrors", e.response.data.error);
      return false;
    }
  },

  async deleteStep(context, payload) {
    try {
      context.commit("removeStep", payload);
      await templateApi.deleteStep(payload);
      return true;
    } catch (e) {
      context.commit("setErrors", e.response.data.error);
      return false;
    }
  },

  async updateSection(context, payload) {
    try {
      await templateApi.updateSection(payload);
      context.commit("setErrors", []);
      return true;
    } catch (e) {
      context.commit("setErrors", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  priceErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "verification_price__required") &&
      result.push(i18n.t("inputError.verPriceRequired"));
    errors.find((i) => i === "verification_price__invalid") &&
      result.push(i18n.t("inputError.verPriceInvalid"));
    return result;
  },
  introErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "introduction_text_template__invalid") &&
      result.push(i18n.t("inputError.introInvalid"));
    return result;
  },
  templateSteps({ template }) {
    return template.steps;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
